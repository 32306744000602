import React, { useState, useRef } from "react";
import styled from "styled-components";

import { SanityImage } from "@graphql-types";
import { Container, H1, H2, P } from "@util/standard";
import { icons, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { IconButton, Video } from "../sub";

interface Props {
  heroImage?: SanityImage | null | undefined;
  heroHeader?: string | null | undefined;
  heroSubHeader?: string | null | undefined;
  heroDescription?: string | null | undefined;
  heroVideo?: string | null | undefined;
  useVideo?: boolean | null | undefined;
}

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  position: absolute !important;
  object-fit: cover;
`;

const TextContainer = styled(Container)<{ videoPlayerOpen: boolean }>`
  position: absolute;
  z-index: 3;
  ${({ videoPlayerOpen }) =>
    videoPlayerOpen ? `left: 1.5%; bottom: 51.5%;` : `left: 8%; bottom: 16%;`};
  transition: left 0.5s;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    ${({ videoPlayerOpen }) =>
      videoPlayerOpen ? `left: 4%; bottom: 85%;` : `left: 8%; bottom: 5%;`};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ videoPlayerOpen }) =>
      videoPlayerOpen
        ? `left: 7%; bottom: 85%;`
        : `left: 8%; bottom: 16%; right: 8%;`};
  }
`;

const PlayButton = styled.img`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  z-index: 4;
  opacity: 1;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }

  transition: all 0.2s;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 56px;
    top: 40%;
  }
`;

const Overlay = styled.div<{ backgroundOn?: boolean }>`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  ${({ backgroundOn }) =>
    backgroundOn
      ? `background: transparent;`
      : `
  
  background: linear-gradient(
    91deg,
    rgba(6, 15, 37, 0.74) 13%,
    rgba(6, 15, 37, 0) 92.86%
  );
  `}

  transition: all 2s;

  z-index: 1;
`;

function InnerHero(props: Props) {
  const {
    heroImage,
    heroHeader,
    heroSubHeader,
    heroDescription,
    heroVideo,
    useVideo,
  } = props;

  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);
  const videoRef = useRef<HTMLVideoElement>();

  const handleVideoPlay = () => {
    setVideoPlayerOpen(true);
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
      }
    }, 500);
  };

  return (
    <Container
      width="100vw"
      height="100vh"
      position="relative"
      backgroundColor="navy"
    >
      <Container position="absolute" width="100%" height="100%">
        {useVideo && (
          <>
            {!videoPlayerOpen && (
              <PlayButton
                src={icons.playButton}
                onClick={() => handleVideoPlay()}
              />
            )}
            <Video
              id={heroVideo.vimeoUrl}
              url={heroVideo.vimeoUrl}
              volume={videoPlayerOpen}
              currentTime={videoPlayerOpen ? 0 : null}
              pause={!videoPlayerOpen}
              foregroundOpen={videoPlayerOpen}
              autoPlayOnLoad={true}
            />
          </>
        )}
      </Container>
      <TextContainer videoPlayerOpen={videoPlayerOpen}>
        {!videoPlayerOpen ? (
          <IconButton
            buttonText="BACK"
            url="/"
            reversed
            icon="leftIcon"
            margin="0px 0px 20px 0px"
          />
        ) : (
          <IconButton
            buttonText="Close"
            onClick={() => setVideoPlayerOpen(false)}
            reversed
            icon="closeIcon"
            zIndex={4}
          />
        )}
        {!videoPlayerOpen && (
          <>
            <H1
              fontSize={90}
              mobileFontSize={45}
              lineHeight={90}
              mobileLineHeight={45}
              width="50%"
              opacity={0.5}
              noMargin
            >
              {heroHeader?.toUpperCase()}
            </H1>
            <H2
              fontSize={25}
              mobileFontSize={20}
              width="53.4%"
              mobileWidth="100%"
              margin="10px 0px"
              color="white"
            >
              {heroSubHeader?.toUpperCase()}
            </H2>
            <P>{heroDescription}</P>
          </>
        )}
      </TextContainer>
      <Overlay backgroundOn={videoPlayerOpen} />
    </Container>
  );
}

export default InnerHero;
