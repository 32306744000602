import React, { useRef } from "react";
import Slider from "react-slick";
import ReactSlick from "react-slick";
import Img from "gatsby-image";
import styled from "styled-components";

import { Container } from "@util/standard";
import { SanitySlider } from "@graphql-types";
import { icons } from "@util/constants";
interface Props {
  content: SanitySlider;
}

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
`;

const Arrow = styled.img<{ left?: boolean; right?: boolean; }>`
  position: absolute;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);

  ${({ left }) => left && `left: 6%;`}
  ${({ right }) => right && `right: 6%;`}
`;

const CustomSlider = styled(Slider)`
  ul.slick-dots {
    position: absolute;
    bottom: 5%;

    li {
      width: 8px;
      height: 8px;
      background-color: white;
      border-radius: 50%;
      opacity: 0.4;
      button {
        opacity: 0;
      }
    }
    .slick-active {
      background-color: white;
      opacity:1;
    }
  }
`;

function ImageSlider(props: Props) {
  const { content } = props;

  if (content == null) {
    return null;
  }

  const { sliderContent } = content;

  if (sliderContent == null) {
    return null;
  }

  const sliderSettings = {
    infinite: true,
    autoPlay: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const sliderRef = useRef<ReactSlick>(null);

  return (
    <Container width="100%" position="relative" margin="5% auto">
      <CustomSlider {...sliderSettings} ref={sliderRef}>
        {sliderContent.map((item) => {
          return (
            <Container
              height="80vh"
              width="100%"
              tabletHeight="50vh"
              mobileHeight="35vh"
              key={item?._key}
            >
              <StyledImg fluid={item?.asset?.fluid} />
            </Container>
          );
        })}
      </CustomSlider>
      <Arrow
        src={icons.leftIcon}
        left
        onClick={() => sliderRef.current?.slickPrev()}
      />
      <Arrow
        src={icons.rightIcon}
        right
        onClick={() => sliderRef.current?.slickNext()}
      />
    </Container>
  );
}

export default ImageSlider;
