import ImageSlider from "@components/shared/innerPages/imageSlider";
import QuoteBlock from "@components/shared/innerPages/quoteBlock";
import TextImageVideoBlock from "@components/shared/textImageVideoBlock/textImageVideoBlock";
import { Maybe, SanityQuoteBlockOrTextImageVideoBlock } from "@graphql-types";
import React from "react";

interface Props {
  pageContent:
  | Maybe<Array<Maybe<SanityQuoteBlockOrTextImageVideoBlock>>>
  | undefined
  | null;
  isHistory?: boolean;
}

function FilterRenderedComponents(props: Props) {
  const { pageContent, isHistory } = props;

  if (pageContent == null) {
    return null;
  }
  return (
    <>
      {pageContent.map((component) => {
        return component._type === "textImageVideoBlock" ? (
          <TextImageVideoBlock content={component} key={component._key} isHistory={isHistory} />
        ) : component._type === "quoteBlock" ? (
          <QuoteBlock content={component} key={component._key} />
        ) : component._type === "slider" ? (
          <ImageSlider content={component} key={component._key} />
        ) : null;
      })}
    </>
  );
}
export default FilterRenderedComponents;
