import React, { useEffect, useState } from "react";
import Img, { FluidObject } from "gatsby-image";
import styled from "styled-components";

import { Container, H3 } from "@util/standard";
import { colors, IPAD_PRO_BREAKPOINT, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { BlocksContent, IconButton, VideoPopUp } from "@sub";
import { SanityTextImageVideoBlock } from "@graphql-types";

interface Props {
  content: SanityTextImageVideoBlock;
  isHistory?: boolean;
}

const ContentContainer = styled(Container) <{ reversed?: boolean; }>`
  display: flex;
  flex-wrap: wrap;

  ${({ reversed }) => reversed && `flex-direction:row-reverse;`};
`;

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
`;

const TextContainer = styled(Container) <{
  textOnly: boolean;
  reversed?: boolean;
  isHistory?: boolean;
}>`
  text-align: left;
  width: 50%;
  ${({ textOnly }) =>
    textOnly &&
    `text-align: center; width: 30%; margin: auto !important`};
  min-width: 427px;
  margin: ${(props) =>
    props.reversed ? `auto auto auto 13%` : `auto 13% auto auto`};
  p {
    font-size: 16px;
    line-height: 20px;
  }
  h2,
  h3,
  h4 {
    font-size: 20px;
    color: ${colors.grey};
    font-family: "bold";
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    margin: ${(props) =>
    props.reversed ? `auto auto auto 13%` : `auto 3% auto auto`};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: ${(props) =>
    props.reversed ? `auto auto 20% auto` : `auto 13% 20% auto`};
    ${({ textOnly }) =>
    textOnly &&
    `text-align: center; width: 30%; margin: 5% 13% 6% auto !important`};
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    ${({ textOnly, isHistory }) =>
    textOnly && !isHistory ?
      `text-align: center; width: 30%; margin: 5% 16% auto auto !important;`
      : textOnly && isHistory ?
        `text-align: center; width: 30%; margin: 10% 16% auto auto !important;`
        : `text-align: left;`};
    min-width: 0px;
    width: 64%;
    margin-bottom: 80px;
    p {
      font-size: 14px;
    }
  }
`;

const ImageButton = styled(Container)`
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: -12%;
  }
`;

const Overlay = styled.div<{ hoverEffect: boolean; alignment: boolean; }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: transparent;
  margin: ${({ alignment }) => (alignment ? "0 0 0 -30px" : "0 -30px 0 0")};

  ${({ hoverEffect }) =>
    hoverEffect &&
    `
  background: rgba(6, 15, 37, 0.62);
`}

  transition: all 0.2s;
`;

function TextImageVideoBlock(props: Props) {
  const { content, isHistory } = props;

  const {
    alignment,
    blockVideo,
    blockLink,
    blockImage,
    blockText,
    useVideo,
    videoThumbnail,
  } = content;

  const [isTabletWidth, setIsTabletWidth] = useState(false);
  const [hoverEffectOn, setHoverEffectOn] = useState(false);
  const [videoPopUpOpen, setVideoPopUpOpen] = useState(false);

  const checkForTabletWidth = () => {
    window.innerWidth <= TABLET_BREAKPOINT
      ? setIsTabletWidth(true)
      : setIsTabletWidth(false);
  };

  if (content == null) {
    return null;
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      checkForTabletWidth();
      window.addEventListener("resize", checkForTabletWidth);
    }

    return () => {
      window.removeEventListener("resize", checkForTabletWidth);
    };
  });

  const isTextOnly = blockImage === null && blockVideo === null;

  const handleVideo = () => {
    setVideoPopUpOpen(!videoPopUpOpen);
  };

  const blockLinkHasContent =
    blockLink !== null && blockLink?.linktext !== null;

  return (
    <>
      <Container margin={isTextOnly ? "0px" : "70px 0px"} mobileMargin={isTextOnly ? "0px" : "30px auto 40px auto"}>
        <ContentContainer reversed={alignment as boolean}>
          {blockText && (
            <Container width="50%" margin="auto" mobileWidth="100%">
              <TextContainer
                isHistory={isHistory}
                textOnly={isTextOnly}
                reversed={alignment as boolean}
              >
                <BlocksContent blocks={blockText._rawContent} width="90%" />
                {blockLinkHasContent && isTabletWidth && (
                  <Container
                    margin={isTextOnly ? "10px auto 0px auto" : "auto"}
                    width={isTextOnly ? "60%" : "100%"}
                  >
                    <IconButton
                      url={blockLink?.url}
                      buttonText={blockLink?.linktext}
                      darkVersion
                    />
                  </Container>
                )}
              </TextContainer>
            </Container>
          )}
          {(blockImage || useVideo) && (
            <Container
              width="50%"
              tabletWidth="100%"
              height="506px"
              mobileHeight="271px"
              position="relative"
              onMouseEnter={() => setHoverEffectOn(true)}
              onMouseLeave={() => setHoverEffectOn(false)}
            >
              {/* Checks for if video is turned on + if there is an existing image */}
              {useVideo ? (
                <Container
                  width="100%"
                  height="100%"
                  onClick={() => setVideoPopUpOpen(true)}
                >
                  <StyledImg fluid={videoThumbnail?.asset?.fluid} />
                  {hoverEffectOn && blockLinkHasContent && (
                    <ImageButton
                      position="absolute"
                      width="17%"
                      height="10%"
                      top="45%"
                      left="45%"
                    >
                      <IconButton
                        url={blockLink?.url}
                        icon="playButton"
                        imgHeight={50}
                        imgWidth={50}
                      />
                    </ImageButton>
                  )}
                  {!hoverEffectOn && blockLinkHasContent && (
                    <ImageButton
                      position="absolute"
                      width="17%"
                      height="10%"
                      top="90%"
                      left="77%"
                    >
                      <IconButton
                        url={blockLink?.url}
                        buttonText="WATCH VIDEO"
                      />
                    </ImageButton>
                  )}
                </Container>
              ) : blockImage ? (
                <Container
                  width="100%"
                  height="100%"
                  margin={alignment ? "0 0 0 -30px" : "0 -30px 0 0"}
                  tabletMargin="auto"
                >
                  <StyledImg fluid={blockImage?.asset?.fluid as FluidObject} />
                  {!hoverEffectOn && blockLinkHasContent && (
                    <ImageButton position="absolute" bottom="20px" right="30px">
                      <IconButton url={blockLink?.url} buttonText="MORE" />
                    </ImageButton>
                  )}
                </Container>
              ) : null}
              {blockLinkHasContent && hoverEffectOn && !isTabletWidth && (
                <Container
                  position="absolute"
                  width="100%"
                  height="100%"
                  top="40%"
                  left="0px"
                  zIndex={2}
                >
                  <H3
                    fontSize={30}
                    fontFamily="bold"
                    width="35%"
                    mobileWidth="60%"
                    margin="auto"
                    textAlign="left"
                    color="white"
                  >
                    {blockLink?.linktext?.toUpperCase()}
                  </H3>
                  <Container
                    width="35%"
                    mobileWidth="60%"
                    margin="10px auto 0px auto"
                  >
                    <IconButton url={blockLink?.url} buttonText="MORE" />
                  </Container>
                </Container>
              )}
              {!useVideo && !isTabletWidth && (
                <Overlay
                  hoverEffect={hoverEffectOn && blockLinkHasContent}
                  alignment={alignment as boolean}
                />
              )}
            </Container>
          )}
        </ContentContainer>
      </Container>
      {videoPopUpOpen && (
        <VideoPopUp
          videoUrl={
            blockVideo ? (blockVideo.uploadedVideo?.asset?.url as string) : ""
          }
          thumbnail={videoThumbnail}
        >
          <IconButton
            buttonText="Close"
            onClick={() => setVideoPopUpOpen(false)}
            reversed
            icon="closeIcon"
          />
        </VideoPopUp>
      )}
    </>
  );
}

export default TextImageVideoBlock;
