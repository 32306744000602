import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import InnerHero from "@components/shared/innerPages/innerHero";
import FilterRenderedComponents from "@util/filterRenderedComponents";

interface Props extends PageProps {
  data: Query;
}

function PeopleInner({ data }: Props) {
  const {
    allSanityPeople: {
      edges: [{ node: person }],
    },
  } = data;

  if (person == null) {
    return null;
  }

  return (
    <>
      <InnerHero
        heroImage={person.innerPageContent?.peopleInnerHero?.heroImage}
        heroHeader={person.name}
        heroSubHeader={person.role}
        heroDescription={person.description}
        heroVideo={person.innerPageContent?.peopleInnerHero?.heroVideo}
        useVideo={person.innerPageContent?.peopleInnerHero?.useVideo}
      />
      <FilterRenderedComponents
        pageContent={person.innerPageContent?.pageContent}
      />
    </>
  );
}

export default PeopleInner;

export const query = graphql`
  query peopleQuery($slug: String) {
    allSanityPeople(filter: { slug: { current: { eq: $slug } } }) {
      edges {
        node {
          slug {
            current
          }
          role
          name
          _id
          description
          featureImage {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
              }
            }
          }
          innerPageContent {
            peopleInnerHero {
              useVideo
              heroVideo {
                vimeoUrl
                uploadedVideo {
                  asset {
                    url
                  }
                }
              }
              heroImage {
                asset {
                  fluid {
                    srcWebp
                    srcSetWebp
                    srcSet
                    src
                  }
                }
              }
            }
            pageContent {
              ... on SanityQuoteBlock {
                _key
                _type
                quoteText {
                  _rawContent
                }
                backgroundImage {
                  asset {
                    fluid {
                      srcWebp
                      srcSetWebp
                      srcSet
                      src
                    }
                  }
                }
              }
              ... on SanityTextImageVideoBlock {
                _key
                _type
                alignment
                useVideo
                blockVideo {
                  uploadedVideo {
                    asset {
                      url
                    }
                  }
                }
                videoThumbnail {
                  asset {
                    fluid {
                      srcWebp
                      srcSetWebp
                      srcSet
                      src
                    }
                  }
                }
                blockLink {
                  url
                  linktext
                  newwindow
                }
                blockImage {
                  asset {
                    fluid {
                      srcWebp
                      srcSetWebp
                      srcSet
                      src
                    }
                  }
                }
                blockText {
                  _rawContent
                }
              }
            }
          }
        }
      }
    }
  }
`;
