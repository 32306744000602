import React from "react";
import styled from "styled-components";

import { BlocksContent, Image } from "../sub";
import { SanityQuoteBlock } from "@graphql-types";
import { Container, H2 } from "@util/standard";
import { colors, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";

interface Props {
  content: SanityQuoteBlock;
}

const TextContainer = styled(Container)`
  text-align: center;
  z-index: 2;
  padding: 88px 0px;
  color: ${colors.white};
  opacity: 0.8;
  line-height: 38px;
  h2,
  h3,
  h4,
  h5 {
    font-size: 30px;
    font-family: "bold";
    width: 60%;
    margin: auto;
    min-width: 300px;
  }

  p {
    font-family: "bold";
    opacity: 0.5;
    color: ${colors.white};
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 159px auto;
    h2,
    h3,
    h4,
    h5 {
      font-size: 30px;
      width: 75%;
      line-height: 34px;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 113px auto;
    padding: 50px 0px;
    h2,
    h3,
    h4,
    h5 {
      font-size: 20px;
      line-height: 24px;
      width: 90%;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(6, 15, 37, 0.55);
`;

const QuoteContainer = styled.div<{ backgroundImage: string; }>`
  margin: 4% auto;
  position: relative;
  ${({ backgroundImage }) =>
    backgroundImage
      ? `background-image: url(${backgroundImage});
      background-repeat: no-repeat;
      background-size: cover; `
      : `background: ${colors.navy};`};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 20% auto auto auto;
  }
`;

function QuoteBlock(props: Props) {
  const { content } = props;

  if (content == null) {
    return null;
  }
  return (
    <QuoteContainer
      backgroundImage={content.backgroundImage?.asset?.fluid?.src}
    >
      <Overlay />
      <TextContainer>
        <BlocksContent blocks={content.quoteText?._rawContent} />
      </TextContainer>
    </QuoteContainer>
  );
}

export default QuoteBlock;
